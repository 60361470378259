.collapse-group {
    .no-select;
  margin-bottom: 15px;
}

.collapse-heading {
  cursor: pointer;

  .glyphicon-chevron-down {
    margin-left: 5px;
  }
}

.open-collapse {
  display: block;
}

.closed-collapse {
  display: none;
}

.note-btn.btn {
    &.btn-default:hover, &.btn-default:focus {
        background-color: #b4dbfd;
        color: black;
    }
    min-width: 0px;
    background-color: #eaeaea;
    color: black;
    .note-current-fontname {
        width: 70px;
        display: inline-block;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        margin: -5px;
        padding-right: 3px;
    }
  }

  .note-popover .popover-content > .btn-group, 
  .panel-heading.note-toolbar > .btn-group {
      margin-right: 2px;
  }
  
  .panel-heading.note-toolbar {
    color: #333333;
    background-color: white;
    border-color: #e0e0e0;
  }
  
  .note-editor.note-frame {
    border: 1px solid #dedede;
  }

  .note-palette .note-color-reset {
      color: black;
  }

  .note-editor.note-frame .note-editing-area .note-editable {
      line-height: 1;
  }

  .emoji-menu {  
    left: -90px !important;
    top: 35px;
    width: 202px;
  }

  .fa-smile-o:before {
    font-size: 16px;
    vertical-align: middle;
}

.tooltip {
    font-size: 12px;
}

.tooltip-inner {
    padding: 5px;
    .border-radius(1px);
    background-color: black;
}
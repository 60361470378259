//
// Variables
// --------------------------------------------------

@baseFontSize:          16px;
@baseFontFamily:        @sansFontFamily;
@baseLineHeight:        20px;
@altFontFamily:         @serifFontFamily;

@errorColor:      #ec4444;

// Form states and alerts
// --------------------------------------------------
@warningText:             #c09853;
@warningBackground:       #fcf8e3;
@warningBorder:           darken(spin(@warningBackground, -10), 3%);

@errorText:               #fff;
@errorBackground:         @errorColor;
@errorBorder:             darken(spin(@errorBackground, -10), 3%);

@successText:             #468847;
@successBackground:       #dff0d8;
@successBorder:           darken(spin(@successBackground, -10), 5%);

@infoText:                #3a87ad;
@infoBackground:          #d9edf7;
@infoBorder:              darken(spin(@infoBackground, -10), 7%);

// Grays
// --------------------------------------------------
@black:                 #000;
@grayDarker:            #222;
@grayDark:              #333;
@gray:                  #555;
@grayLight:             #999;
@grayLighter:           #eee;
@white:                 #fff;

// Accent colors
// --------------------------------------------------
@orange:                #f89406;









// Labels and badges
//
// All HTML headings, <h1> through <h6> are available.
//
// markup:
// <ul class="filter">
//  <li class="active"><a href="#">Home</a></li>
//  <li><a href="#">Profile</a></li>
//  <li><a href="#">Messages</a></li>
// </ul>
//
// markup:
// <table class="table table-bordered table-striped">
//   <thead>
//     <tr>
//       <th>Labels</th>
//       <th>Markup</th>
//     </tr>
//   </thead>
//   <tbody>
//     <tr>
//       <td>
//         <span class="label">Default</span>
//       </td>
//       <td>
//         <code>&lt;span class="label"&gt;Default&lt;/span&gt;</code>
//       </td>
//     </tr>
//     <tr>
//       <td>
//         <span class="label label-success">Success</span>
//       </td>
//       <td>
//         <code>&lt;span class="label label-success"&gt;Success&lt;/span&gt;</code>
//       </td>
//     </tr>
//     <tr>
//       <td>
//         <span class="label label-warning">Warning</span>
//       </td>
//       <td>
//         <code>&lt;span class="label label-warning"&gt;Warning&lt;/span&gt;</code>
//       </td>
//     </tr>
//     <tr>
//       <td>
//         <span class="label label-important">Important</span>
//       </td>
//       <td>
//         <code>&lt;span class="label label-important"&gt;Important&lt;/span&gt;</code>
//       </td>
//     </tr>
//     <tr>
//       <td>
//         <span class="label label-info">Info</span>
//       </td>
//       <td>
//         <code>&lt;span class="label label-info"&gt;Info&lt;/span&gt;</code>
//       </td>
//     </tr>
//     <tr>
//       <td>
//         <span class="label label-inverse">Inverse</span>
//       </td>
//       <td>
//         <code>&lt;span class="label label-inverse"&gt;Inverse&lt;/span&gt;</code>
//       </td>
//     </tr>
//   </tbody>
// </table>
//
// <table class="table table-bordered table-striped">
//   <thead>
//     <tr>
//       <th>Name</th>
//       <th>Example</th>
//       <th>Markup</th>
//     </tr>
//   </thead>
//   <tbody>
//     <tr>
//       <td>
//         Default
//       </td>
//       <td>
//         <span class="badge">1</span>
//       </td>
//       <td>
//         <code>&lt;span class="badge"&gt;1&lt;/span&gt;</code>
//       </td>
//     </tr>
//     <tr>
//       <td>
//         Success
//       </td>
//       <td>
//         <span class="badge badge-success">2</span>
//       </td>
//       <td>
//         <code>&lt;span class="badge badge-success"&gt;2&lt;/span&gt;</code>
//       </td>
//     </tr>
//     <tr>
//       <td>
//         Warning
//       </td>
//       <td>
//         <span class="badge badge-warning">4</span>
//       </td>
//       <td>
//         <code>&lt;span class="badge badge-warning"&gt;4&lt;/span&gt;</code>
//       </td>
//     </tr>
//     <tr>
//       <td>
//         Important
//       </td>
//       <td>
//         <span class="badge badge-important">6</span>
//       </td>
//       <td>
//         <code>&lt;span class="badge badge-important"&gt;6&lt;/span&gt;</code>
//       </td>
//     </tr>
//     <tr>
//       <td>
//         Info
//       </td>
//       <td>
//         <span class="badge badge-info">8</span>
//       </td>
//       <td>
//         <code>&lt;span class="badge badge-info"&gt;8&lt;/span&gt;</code>
//       </td>
//     </tr>
//     <tr>
//       <td>
//         Inverse
//       </td>
//       <td>
//         <span class="badge badge-inverse">10</span>
//       </td>
//       <td>
//         <code>&lt;span class="badge badge-inverse"&gt;10&lt;/span&gt;</code>
//       </td>
//     </tr>
//   </tbody>
// </table>
//
// Styleguide 9.0

// Base classes
.label,
.badge {
  display: inline-block;
  padding: 2px 4px;
  font-size: @baseFontSize * .846;
  font-weight: bold;
  line-height: 14px; // ensure proper line-height if floated
  color: @white;
  vertical-align: baseline;
  white-space: nowrap;
  text-shadow: 0 -1px 0 rgba(0,0,0,.25);
  background-color: @grayLight;
}
// Set unique padding and border-radii
.label {
  .border-radius(3px);
}
.badge {
  padding-left: 9px;
  padding-right: 9px;
  .border-radius(9px);
}

// Empty labels/badges collapse
.label,
.badge {
  &:empty {
    display: none;
  }
}

// Hover/focus state, but only for links
a {
  &.label:hover,
  &.label:focus,
  &.badge:hover,
  &.badge:focus {
    color: @white;
    text-decoration: none;
    cursor: pointer;
  }
}

// Colors
// Only give background-color difference to links (and to simplify, we don't qualifty with `a` but [href] attribute)
.label,
.badge {
  // Important (@errorColor)
  &-important         { background-color: @errorText; }
  &-important[href]   { background-color: darken(@errorText, 10%); }
  // Warnings (orange)
  &-warning           { background-color: @orange; }
  &-warning[href]     { background-color: darken(@orange, 10%); }
  // Success (green)
  &-success           { background-color: @successText; }
  &-success[href]     { background-color: darken(@successText, 10%); }
  // Info (turquoise)
  &-info              { background-color: @infoText; }
  &-info[href]        { background-color: darken(@infoText, 10%); }
  // Inverse (black)
  &-inverse           { background-color: @grayDark; }
  &-inverse[href]     { background-color: darken(@grayDark, 10%); }
}

// Quick fix for labels/badges in buttons
.btn {
  .label,
  .badge {
    position: relative;
    top: -1px;
  }
}
.btn-mini {
  .label,
  .badge {
    top: 0;
  }
}

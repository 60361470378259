// Base Module
// 
// File Structure
//
// Within the "/public/less" directory you will find the following file structure
// 
// markup:
// less/
//   |-- style.less
//   |-- base/
//   |   |-- base.less
//   |   |-- forms.less
//   |   |-- normalize.less
//   |   |-- table.less
//   |   config/
//   |   | -- accordion.less
//   |-- layout/
//   |   |-- grid.less
//   |   |-- layout.less
//   |-- module/
//   |   |-- accordian.less
//   |   |-- button.less
//   |   |-- caret.less
//   |   |-- close.less
//   |   |-- filter.less
//   |   |-- labels-badges.less
//   |   |-- modal.less
//   |   |-- nav.less
//   |   |-- navbar.less
//   |   |-- notification.less
//   |   |-- pagination.less
//   |   |-- popovers.less
//   |   |-- select2.less
//   |   |-- button.less
//   |   |-- tooltip.less
//   |   |-- wells.less
//   |-- tools/
//   |   |-- helper.less
//   |   |-- mixin.less
//   |   |-- variables.less
//
// Base CSS
// Styles for common HTML elements like typography, code, tables, forms, and buttons. 
// Also includes Glyphicons, a great little icon set.
// 
// Layout
// Contains classes for grid and pasict page structure.
//
// Modules
// Basic styles for common interface components like tabs and pills, navbar, alerts, page headers, and more.
//
// Tools:
// This directory contains global helper classes and mixins
//
// Styleguide 1.0

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
    display: block;
}

audio, canvas, video {
    display: inline-block;
    *display: inline;
    *zoom: 1;
}

audio:not([controls]) {
    display: none;
}

[hidden] {
    display: none;
}

html {
    color: @base-font-color;
    font-size: @base-font-size;
    line-height: @base-line-height;
    overflow-y: scroll;

    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

// Typography
// Styleguide 1.1
// 
// Body Copy
//
// Global default font-size is 16px, with a line-height of 20px. This is applied to the &lt;body&gt; 
// and all paragraphs. In addition, &lt;p&gt; (paragraphs) receive a bottom margin of half their computed 
// 
// markup:
// <p>Nullam quis risus eget urna mollis ornare vel eu leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam id dolor id nibh ultricies vehicula.</p>
// <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec ullamcorper nulla non metus auctor fringilla. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec ullamcorper nulla non metus auctor fringilla.</p>
//
// Styleguide 1.1.1

body {
    margin: 0;
    font-size: @base-font-size;
    line-height: @base-line-height;
    color: @base-font-color;
    background-color: @base-background-color;

}

body, button, input, select, textarea {
    font-family: @base-font-family;
}

p {
    margin: 0 0 @base-line-height / 2;
}

// Strong
//
// markup:
// <strong>Strong</strong> or <b>Bold</b>
//
// Styleguide 1.1.2

b, strong {
    font-weight: bold;
}

// Headings
//
// All HTML headings, &lt;h1&gt; through &lt;h6&gt;.
//
// markup:
// <h1>h1. heading</h1>
// <h2>h2. heading</h2>
// <h3>h3. heading</h3>
// <h4>h4. heading</h4>
// <h5>h5. heading</h5>
// <h6>h6. heading</h6>
//
// Styleguide 1.2

h1, h2, h3, h4, h5, h6 {
    margin: (@base-line-height / 2) 0;
    font-family: @base-font-family;
    font-weight: @base-header-weight;
    line-height: @base-line-height;
    color: @base-header-color;
    text-rendering: optimizelegibility; // Fix the character spacing for headings
    small {
        font-weight: normal;
        line-height: 1;
        color: @base-header-small-color;
    }
}

h1, h2, h3 {
    line-height: @base-line-height * 2;
}

h1 {
    font-size: @base-font-size * 2.25;
}

h2 {
    font-size: @base-font-size * 2.25;
}

h3 {
    font-size: @base-font-size * 1.75;
}

h4 {
    font-size: @base-font-size * 1.25;
}

h5 {
    font-size: @base-font-size;
}

h6 {
    font-size: @base-font-size * 0.85;
}

// Heading with small
//
// All HTML headings, &lt;h1&gt; through &lt;h6&gt; with small. 
//
// markup:
// <h1>h1. heading <small>small</small></h1>
// <h2>h2. heading <small>small</small></h2>
// <h3>h3. heading <small>small</small></h3>
// <h4>h4. heading <small>small</small></h4>
// <h5>h5. heading <small>small</small></h5>
// <h6>h6. heading <small>small</small></h6>
//
// Styleguide 1.2.1

h1 small {
    font-size: @base-font-size * 1.75;
}

h2 small {
    font-size: @base-font-size * 1.25;
}

h3 small {
    font-size: @base-font-size;
}

h4 small {
    font-size: @base-font-size;
}

// Links
//
// :hover - hover
// :focus - focus
// :active - active
// :visited - visited;
//
// markup:
// <a href="">Link</a>
//
// Styleguide 1.3

a {
    color: @base-link-color;
    text-decoration: underline;
}

a:hover {
    color: @base-link-hover;
    text-decoration: underline;
    cursor: pointer;
}

a:active {
    color: @base-link-active;
    text-decoration: underline;
}

a:focus {
    outline: thin dotted;
}

a:hover, a:active {
    outline: 0;
}

a.btn {
    color: #fff;
    text-decoration: none;
}

a.btn:hover {
    color: #fff;
    text-decoration: none;
}

a.btn:active {
    color: #fff;
    text-decoration: none;
}

a:focus {
    outline: thin dotted;
}

// Address
//
//
// markup:
// <address>
//   <strong>Twitter, Inc.</strong><br>
//   795 Folsom Ave, Suite 600<br>
//   San Francisco, CA 94107<br>
//   <abbr title="Phone">P:</abbr> (123) 456-7890
// </address>
//
// <address>
//   <strong>Full Name</strong><br>
//   <a href="mailto:#">first.last@example.com</a>
// </address>
//
// Styleguide 1.4

address {
    display: block;
    margin-bottom: @base-line-height;
    font-style: normal;
    line-height: @base-line-height;
}

// Abbr
//
// Added data-* attribute to help out our tooltip plugin, per https://github.com/twitter/bootstrap/issues/5257
//
// markup:
// The <abbr title="World Health Organization">WHO</abbr> was founded in 1948.
//
// Styleguide 1.5

abbr[title],
abbr[data-original-title] {
    cursor: help;
    border-bottom: 1px dotted;
}

// Dfn
//
// markup:
// <dfn>Definition term</dfn>
//
// Styleguide 1.6

dfn {
    font-style: italic;
}

// Ins
//
// markup:
// <p>My favorite color is <del>blue</del> <ins>red</ins>!</p>
//
// Styleguide 1.7

ins {
    background: #ff9;
    color: #000;
    text-decoration: none;
}

// Mark
//
// markup:
// <p>Do not forget to buy <mark>milk</mark> today.</p>
//
// Styleguide 1.8

mark {
    background: #ff0;
    color: #000;
    font-style: italic;
    font-weight: bold;
}

// Code, Kbd, Samp
//
// Redeclare monospace font family: en.wikipedia.org/wiki/User:Davidgothberg/Test59
//
// markup:
// <code>A piece of computer code</code>
// <kbd>Keyboard input</kbd>
// <samp>Sample output from a computer program</samp>
//
// Styleguide 1.9

pre, code, kbd, samp {
    font-family: monospace, monospace;
    _font-family: 'courier new', monospace;
    font-size: 1em;
}

// Pre
//
// Improve readability of pre-formatted text in all browsers
//
// markup:
// <pre>Text in a pre element is displayed in a fixed-width font, and it preserves both      spaces and line breaks</pre>
//
// Styleguide 1.10

pre {
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word;
}

// Quote
//
// Improve readability of pre-formatted text in all browsers
//
// markup:
// <p>WWF's goal is to: <q>Build a future where people live in harmony with nature.</q> We hope they succeed.</p>
//
// Styleguide 1.11

q {
    quotes: none;
}

q:before, q:after {
    content: "";
    content: none;
}

// Blockquote
//
// markup:
// <blockquote cite="http://www.worldwildlife.org/who/index.html">
// <p>For 50 years, WWF has been protecting the future of nature. The world's leading conservation organization, WWF works in 100 countries and is supported by 1.2 million members in the United States and close to 5 million globally.</p>
// <small>Luke Miller</small>
// </blockquote>
//
// Styleguide 1.12

blockquote {
    padding: 0 0 0 15px;
    margin: 0 0 @base-line-height;
    border-left: 5px solid @border-color;
    p {
        margin-bottom: 0;
        font-size: @base-font-size * 1.25;
        font-weight: 300;
        line-height: 1.25;
    }
    small {
        display: block;
        line-height: @base-line-height;
        color: @border-color;
        &:before {
            content: '\2014 \00A0';
        }
    }

}

// Small
//
// markup:
// <p>W3Schools.com - the world's largest web development site.</p>
// <p><small>Copyright 1999-2050 by Refsnes Data</small></p>
//
// Styleguide 1.13

small {
    font-size: 85%;
}

// Sub, Sup
//
// Position subscript and superscript content without affecting line-height: gist.github.com/413930
//
// markup:
// <p>This text contains <sup>superscript</sup> text.</p>
// <p>This text contains <sub>subscript</sub> text.</p>
//
// Styleguide 1.14

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

// Lists
//
// Styleguide 1.15

// UL, OL
//
// markup:
// <ol>
//  <li>Coffee</li>
//  <li>Tea</li>
//  <li>Milk</li>
// </ol>
//
// <ul>
//  <li>Coffee</li>
//  <li>Tea</li>
//  <li>Milk</li>
// </ul>
//
// Styleguide 1.15.1

ul, ol {
    padding: 0;
    margin: 0 0 @base-line-height / 2 25px;
}

ul ul,
ul ol,
ol ol,
ol ul {
    margin-bottom: 0;
}

// UL, OL Unstyled
//
// Remove default list styles
//
// markup:
// <ol class="unstyled">
//  <li>Coffee</li>
//  <li>Tea</li>
//  <li>Milk</li>
// </ol>
//
// <ul class="unstyled">
//  <li>Coffee</li>
//  <li>Tea</li>
//  <li>Milk</li>
// </ul>
//
// Styleguide 1.15.2

.unstyled-list,
ul.unstyled,
ol.unstyled {
    margin-left: 0;
    list-style: none;
}

// UL, OL Inline
//
// Single-line list items
//
// markup:
// <ol class="inline">
//  <li>Coffee</li>
//  <li>Tea</li>
//  <li>Milk</li>
// </ol>
//
// <ul class="inline">
//  <li>Coffee</li>
//  <li>Tea</li>
//  <li>Milk</li>
// </ul>
//
// Styleguide 1.15.3

ul.inline,
ol.inline {
    margin-left: 0;
    list-style: none;
    > li {
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
    }
}

li {
    line-height: @base-line-height;
}

// DD
//
// markup:
// <dl>
//  <dt>Coffee</dt>
//    <dd>Black hot drink</dd>
//  <dt>Milk</dt>
//    <dd>White cold drink</dd>
// </dl>
//
// Styleguide 1.16
dl {
    margin-bottom: @base-line-height;
}

dt,
dd {
    line-height: @base-line-height;
}

dt {
    font-weight: bold;
}

dd {
    margin-left: @base-line-height / 2;
}

// Images
//
// Improve image quality when scaled in IE7
// code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/
//
// Styleguide 1.17

// Img
//
// markup:
// <img src="smiley.gif" alt="Smiley face" height="42" width="42">
//
// Styleguide 1.17.1

img {
    border: 0;
    -ms-interpolation-mode: bicubic;
}

svg:not(:root) {
    overflow: hidden;
}

// Img
//
// markup:
// <figure>
//  <img src="img_pulpit.jpg" alt="The Pulpit Rock" width="304" height="228">
// </figure>
//
// Styleguide 1.17.2

figure {
    margin: 0;
}

// Tables
//
// Styleguide 1.18

// Table
//
// markup:
// <table>
//   <tr>
//     <th>Month</th>
//     <th>Savings</th>
//   </tr>
//   <tr>
//     <td>January</td>
//     <td>$100</td>
//   </tr>
// </table>
//
// Styleguide 1.18.1

table {
    border-collapse: collapse;
    border-spacing: 0;
}

// Horizontial Rule
//
// markup:
// <hr>
//
// Styleguide 1.19

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid @base-hr-color;
    margin: 1em 0;
    padding: 0;
}

/*
 * Remove the gap between images, videos, audio and canvas and the bottom of
 * their containers: h5bp.com/i/440
 */

audio,
canvas,
img,
svg,
video {
    vertical-align: middle;
}

a [class*=" icon-"], a [class^=icon-] {
    text-decoration: none;
}

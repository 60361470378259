//
// Print
// --------------------------------------------------

@media print {

  // Header/Footer
  // --------------------------------------------------
  // hide header and footer.
  header, footer,
  .nav, .pagination,
  .dataTables_filter,
  .dataTables_info,
  .dataTables_length {
    display: none !important;
  }

  // hide URL print
  // --------------------------------------------------
  // hide URL information after the href
  // this should be used on all navigation links
  // or none content links
  a[href]:after {
    content: "" !important;
  }

  // No Print
  // --------------------------------------------------
  // hide all content with a class on noprint
  // use this class on any element that you would like to
  // hide when printing the page.
  .no-print {
    display: none !important;
  }

  // Accordian
  // --------------------------------------------------
  // Expand the accordian content.
  // Used on the product details pages.
  .closed-accordion > tbody {
    display: table-row-group !important;
  }

  // Page Break
  // --------------------------------------------------
  // Insert a page break
  .page-break {
    display: block;
    page-break-before: always;
  }

  html,
  body,
  .odd,
  .even {
    text-shadow: none !important;
    color: @print-color !important; // Black prints faster: h5bp.com/s
    background: @print-background-color !important;
    background-color: @print-background-color !important;
    box-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  // Don't show links for images, or javascript/internal links
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  pre,
  blockquote {
    border: @print-blockquote-border;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group; // h5bp.com/t
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  @page {
    margin: 0.5cm;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .light-grey {
    background-color: @print-light-grey-background-color;
    color: @print-color;
  }

  .light-orange {
    color: @print-color;
  }

  .light-purple {
    color: @print-color;
  }

  .dark-blue {
    background-color: @print-dark-blue-background-color;
    color: @print-color;
  }

}

// Group == heading + body
.accordion-group {
  border: @accordion-border;
}

// Inner needs the styles because you can't animate properly with any styles on the element
.accordion-inner {
  padding: 9px 15px;
}

/* Show/Hide info data */
.open-accordion .summary {
  display: none;
}

.open-accordion thead tr th {
  cursor: pointer;
}

// Facilitate the diplay/animations */
.closed-accordion .summary {
  display: inline;
}

.closed-accordion thead tr th {
  cursor: pointer;
}

.accordion tbody {
  display: block;
}

.accordion {
  margin: 0;
}

.accordion-group {
  border: 0;
}

.accordion-heading .accordion-toggle {
  background-color: @accordion-heading-background-color;
  color: @accordion-heading-color;
  border: 0;
  padding: 8px;
  line-height: @baseLineHeight;
  text-align: left;
  font-weight: bold;
}

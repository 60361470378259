//
//  Login screen
// --------------------------------------------------

// Module color variable
@form-color: mix(@textColor, @inverse, 9%);

.login {
  background-size: 940px 778px;
  color: @inverse;
  margin: 60px;
  margin-bottom: 30px;
  margin-top: 50px;
  position: relative;
}

.header-wrapper {
  margin-bottom: 20px;
}

.login-form-wrapper {
  margin-left:06px;
  .login-form-wrap {
    padding-left: 0;
    padding-top: 10px;
  }
}

.lt-ie9 div.login {
  background-color:#2089C4;
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  border:10px solid #2089C4;

}


.login-icon {
  left: 200px;
  position: absolute;
  top: 160px;
  width: 96px;

  > img {
    display: block;
    margin-bottom: 6px;
    width: 100%;
  }
  > h4 {
    font-size: 17px;
    font-weight: 200;
    line-height: 34px;
    .opacity(95);

    small {
      color: inherit;
      display: block;
      font-size: inherit;
      font-weight: 700;
    }
  }
}


.login-page-snippet-block {
  padding-top:0px;
  padding-left:30px;
  margin-right:20px;
  border-right:1px solid #fff;
  line-height:28px;
}

// LOGIN FORM
// -----------
.login-form {
  background-color: transparent;
  padding: 24px 23px 20px;
  position: relative;
  .border-radius(6px);
  color:#000;
  width: 315px;

  // Ear
  &:before {
    content: '';
    border-style: solid;
    border-width: 12px 12px 12px 0;
    border-color: transparent transparent transparent transparent;
    height: 0;
    position: absolute;
    left: -12px;
    top: 35px;
    width: 0;
    -webkit-transform: rotate(360deg); // Make corners smooth
  }
  .form-group {
    margin-bottom: 6px;
    position: relative;
  }
  .login-field-icon {
    color: mix(@gray, @inverse, 60%);
    font-size: 22px;
    position: absolute;
    right: 23px;
    top: 14px;
    .transition(.25s);
  }
}
.login a {
color: #fff;
}
.login-link {
  color: mix(@gray, @inverse, 60%);
  display: block;
  font-size: 13px;
  margin-top: 15px;
  text-align: center;
}


.login-form .login-field {
  border-color: transparent;
  font-size: 17px;
  padding-bottom: 11px;
  padding-top: 11px;
  text-indent: 10px;
  width: 299px;
  margin-bottom: 10px!important;
  font-family:verdana !important;
  height: 45px;
  color:#000;
}


#notification-create{
    label {
        line-height: 2.5em;
    }
    a#manage-templates{
        line-height: 2.5em;
    }
}

#notification-template-create {
    label {
        line-height: 2.5em;
    }
    .publish-status {
        .error {
            display: none;
        }
    }
    .publish-status.error {
        .error {
            display: block;
        }
        .form-group {
            margin-bottom: 0px;
        }
        .select2-container .select2-choice {
            border-color: red;
        }
    }
}

#notification-recipients{
    .filters-recipients {
        padding-left: 10px;
    }
    .select2-container-multi.filter-recipients,
    .select2-container-multi.filter-recipients-users {
        width: 100% !important;
        .select2-search-field {
            height: 45px;
        }
    }
}

.amount-users-added {
    color: #b10eda;
    padding-left: 5px;
    font-size: 13px;
}

.label-timezone {
    text-align: right;
    font-weight: bold;
    margin-top: 7px;
}
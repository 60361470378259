#header_notifications {
}


.login-link{
    text-align: left;
    color:#fff;
}



.login {
    color:#fff;
    padding-bottom:36px;
    margin-bottom:49px;
    background-color:rgba(0,105,170,0.4);
    border-color:rgba(0,105,170,0.2);
}

.login-link {
    margin-top: 5px;
}





.currentseason {
    background-color:#f9bf03 !important;
    display:block;
    vertical-align:middle;
    height:45px;
    width:26px;
    padding:0px;
    margin:0px;
   padding-bottom:30000px;
    margin-bottom:-30000px;
}


.form-group .error .control-label {
    color:Red;
}


.disabled .icon-edit {
    background-position:-336px -120px;
}





.icon-small{
    font-size:09px;
}

.icon-edit {
    cursor:pointer;
}



.indent-small {
    text-indent:10px;
}



.expired td{
    color:gray;
}
.expired td a{
    color:#666;
}

body.modal-open {
    overflow: hidden;
}
.truncate {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.select2-container.hidden {
    display: none;
}
.origination {
    width:6px;
    padding:0px;
    margin:0px;
    height:1000px;
}
.light-grey{
    background-color:#868686;
    color:#fff;
}
.light-orange{
    background-color:#ff7112;
    color:#fff;
}
.light-purple{
    background-color:#a860c4;
    color:#fff;
}
.light-green{
    background-color:green;
}
.dark-blue{
    background-color:#2697DD;
    color:#fff;
}
.dark-orange{
    background-color:#F88017;
    color:#fff;
}
.dark-green{
    background-color:#254117;
    color:#fff;
}
.dark-yellow{
    background-color:#ff0;
}
.border-top{border-bottom:1px solid #000;}

img { max-width:100%;}

// Adding css rules for title searching.

.search-title .episode_filters,
.search-title .season_dropdown,
.search-title .program_dropdown {
    display: none;
}

.search-results tr .name, .ajax-datatables tr .name, .ajax-datatables tr .title {
    min-width: 200px;
}


// inset datepicker style
.datepicker-group {
    position: relative;
}

.datepicker-group > input[type="text"]{
    margin-bottom:0px;
}
.datepicker-group .ui-datepicker-trigger {
    position: absolute;
    top: -3px;
    right: 6px;
    z-index: @zindexDatePickerTriggerInGroup;
}

.window_date_group .datepicker-group {
    margin: 0 10px;
}

.order_form_field {
    clear:both;
    padding-top: 10px;
}

.order_form_field .noLink {
    color: #34495e;
}

// thumbnail area on misc pages.
.thumbnail_holder {
    width: 200px;
    text-align: center;
}

.thumbnail_holder .btn {
    margin-top: 12px;
    width: 80%;
}

.thumbnail_holder img.thumbnail {
    width: 100%;
}


/*Asset Ordering Stuff*/

.title .thumbnail{
    padding: 0px;
    border: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.modal.asset-details {
    width: 900px;
    margin-left: -450px;
}

.orderablelist td {}

.orderablelist .name-details {
    height: 50px;
    position: relative;
 }

.orderablelist .file-spec {
    position: absolute;
    width: 650px;
    bottom: 0;
}

/*hack to allow absolute positioning inside td in firefox*/
.tdWrapper {
    height: 100%;
    width: 100%;
    position: relative;
}

.orderablefiles .filters {
    margin-bottom: 20px;
}

.label-sm {
    text-transform: uppercase;
    font-size: 12px;
    background-color: transparent;
    color:#a6a6a6;
    border:1px solid #a6a6a6;
    border-radius: 3px;
    padding: 2px 8px;
    text-shadow:0 0px 0 rgba(0,0,0,.0);
}

a.clear-filter {
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.2;
    margin-right: 16px;
}

a.clear-filter:hover {
    opacity: 1.0;
    cursor: pointer;
}
.order-submit-button,
.delivery_date {
    clear: both;
}

#order_cart_form table {
    width: 100%;
}

.program .name {
    padding-left: 15px;
    width: 212.5px;
}

.season .name {
    padding-left: 30px;
    width: 197.5px;
}

.episodes .title .name {
    padding-left: 45px;
    width: 182.5px;
}

.episodes .title .filename {
    padding-left: 79px;
    width: 167.5px;
}

.assetlist .label.inherited {
    background: #BBB;
}

.assetlist .label.inherit-selected {
    background: #7BC9FA;
    background: #73c9ff;
    background: #2697dd;
}

.assetlist .label.selected {
    background: orange;
    background: #2697dd;
    background: #013e66;
}

.expander, .expanderText {
    cursor: pointer;
}


#delivery_file, #delivery_tape {
    margin-left: 5px;
    position: relative;
    top: 5px;
}

.tab-pane table td, .tab-pane table th {
padding: 8px;
}



.delivery-methods {
    margin-bottom: 10px;
}

tr .check {
    width: 1px;
}

.channel {
    clear: both;
}

.icon-remove {
    cursor: pointer;
}

.comment-list {
    list-style-type: none;
    margin-left: 0;
    width: 600px;
}

.comment-list li {
    margin-bottom: 1em;
    border: 1px #ccc solid;
    border-radius: 5px;
    padding: 0.5em;
}

.comment-list .user {
    margin-left: 8px;
    font-weight: bold;
}

.comment-list .delete {
    margin-left: 8px;
}

.comment-list .comment-content {
    margin-bottom: 8px;
}

.comment-list .attachment {
    font-size: 13px;
    font-weight: bold;
}
.comment-list .attachment a {
    font-size: 14px;
    font-weight: normal;
}

.comment-info {
    font-size: 13px;
}


.disclaimer p {
    line-height: 1.1;
}

.bonus_materials_checkbox {
    top: 10px;
}
.bonus_artwork_checkbox {
    top: 15px;
}

#bonus_delivery {
    padding-top: 20px;
    clear: both;
}

.datepicker-group .ui-datepicker-trigger {
    position: absolute;
    top: -7px;
    right: 6px;
}

.configure-specs-submit {
    position: relative;
    top: 30px;
}

.output {
    margin-right: 3px;
}

li.select2-result.select2-result-unselectable.select2-disabled{
    display: none;
}

input[type='text'].input-with-error {
    border-color: #e9322d;
    -webkit-box-shadow: 0 0 6px #f8b9b7;
    -moz-box-shadow: 0 0 6px #f8b9b7;
    box-shadow: 0 0 6px #f8b9b7;
}


.radioOutputSpecLabel {
    margin-right: 0;
}
#emailcheck {
    margin-right:06px;
}

input[type="text"].datepicker,
input[type="text"].datepicker-full {
    background: #fff url('/img/calendar.png') no-repeat 114px 3px;
    width:145px;
    padding:04px 06px;
}

#titles_container_feature >
                    tbody >
                    tr:first-child
                    .add_product_week_previous {
    display:none;
}
#titles_container_feature >
                    tbody >
                    tr:first-child
                    .add_product_day_previous {
    display:none;
}
#titles_container_doa >
                    tbody >
                    tr:first-child
                    .add_product_day_previous {
    display:none;
}
#titles_container_doa >
                    tbody >
                    tr:first-child
                    .add_product_week_previous {
    display:none;
}

/*dropdown admin page*/
.popover-markup, .dropdown-toggle {
    display: inline;
}

/* there is a btn-small class already I don't want to muck with */
.btn-little {
    padding: 2px 5px 3px;
}

.aspera_progress_box {
    width: 100px;
    border: 1px solid #000;
    background: #ddd;
    float: left;
    margin-right: 15px;
    height: .7em;
    padding: 1px;
}

.aspera_upload_info {
    height: 1.5em;
}

.aspera_progress_bar {
    background: #2697dd;
    height: 100%;
}

.aspera_textbox {
    display: inline-block;
    margin-left: 10px;
    position: relative;
    top: 2px;
}

.btn-small {
    padding: 2px 5px 3px;
}

/* aspera-error-page */

.aspera_error_message, .aspera_installer_link {
    width: 100%;
    max-width: 800px;
    padding: 20px;
    margin: auto;
}

.aspera_installer_link {
    padding-top: 0;
}

.cc_aspera_textbox, .preview_aspera_textbox {
    display: inline-block;
}

#asset_upload_video .popover-markup {
    /*display: none;*/
}

.disabled-download input { display: none; }

.disabled-download td { font-style: italic; color: #ccc; }

.disabled-download .file-spec .label-sm { background-color: #ccc; }

.filter button.btn {
    margin-top: 2px;
    margin-bottom: 2px;
    padding: 6px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 4px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 8px;
}

.export-buttons form {
    display: inline;
}

.chckbox label {
    display: inline;
}

body[section="movieFeature"] .programTV { display: none; }
body[section="programTV"] .movieFeature { display: none; }

.title_select_spinner_container {
    height: 15px;
}

.title_select_spinner_container .spinner {
    margin: auto;
    display: block;
}

.addToRowColumn {
    width: 120px;
}

.removeColumn {
    width: 80px;
}


.title_authenticated,
.title_en_thumbnail,
.title_fr_thumbnail {
    text-align: center;
}

.movies-order-button-container {
    clear:both;
}

.bootstrap-datetimepicker-widget {
    background-color:#fff;
    z-index:@zindexDatePickerWidget !important;
}

.satellites_menu{margin: 0;}
.satellites_menu .week_view{
    margin-left:84px;
}
.satellites_menu .satdate{
    padding-bottom:20px;
}

.item-per {
  margin-top: 10px;
  .clear-all {
    clear: both;
  }

  .clear-left {
    clear: left;
  }

  .clear-right {
    clear: right;
  }
}
#organization_link_box {
  max-width: 276px;
  overflow: hidden;
}

.nowrap {
  white-space:nowrap;
}

td.name-details .name, td.artwork_display_name .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
  padding-left: 0px;
}

.copied-invitation {
  background-color: #5cb85c;
  border-color: #4cae4c;
  &:hover {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}

#form-ranged-alt-dates {
  margin-top: 90px;
}

//
// Close icons
// --------------------------------------------------


.close {
  color: #000;
  &:hover,
  &:focus {
    color: #000;
  }
}

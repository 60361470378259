.navbar-nav .list-unstyled {
  margin-left:0px;
}
// Navlist
// --------------------------------------------------
@navListColor :         @white;
@navListBGColor :       @linkColor;

.navbar {
  margin-bottom: 0px;
  border: 0px solid transparent;
  min-height: 38px;
}
.navbar .container {
  margin-bottom: 0px;
}

// Top Level Menu

// Active
.navbar-nav li.dropdown.active > .dropdown-toggle, 
.navbar-nav li.dropdown.open.active > .dropdown-toggle, 
.navbar-nav li.dropdown.open > .dropdown-toggle {
  background-color: #036aaa;
  color: #fff;
}

.subnavbar li.dropdown.active > .dropdown-toggle,
.subnavbar li.dropdown.open.active > .dropdown-toggle,
.subnavbar li.dropdown.open > .dropdown-toggle {
  background-color: #02456e;
  color: #fff;
}

.nav > li > a:hover,
.nav > li > a:focus {
  background-color: #036aaa;
  color:#fff;
}

// Active
.subnavbar .nav > .active > a,
.subnavbar .nav > .active > a:hover,
.subnavbar .nav > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: @navbarLinkBackgroundActive;
  min-height: 27px;
}


// Remove Text Underline
.navbar-nav>li>a {
  text-decoration:none;
}

.large-nav {
  background-color:#fff;
}

.large-nav .navbar-brand {
  height: 130px;
  width: 50%;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  float: left;
  display: block;
  padding: 10px 20px 10px;
}

.large-nav .nav li a {
  text-shadow: none;
  text-decoration:none;
  padding: 12px 16px;
  color:#777;
}

.large-nav .nav li.active a {
  color:#fff;
}

.large-nav .nav.nav-pills {
  margin-top: 20px;
}








//
// Variables
// --------------------------------------------------

@baseLineHeight:        20px;

// Navbar
// --------------------------------------------------
@navbarHeight:                    40px;
@navbarBackgroundHighlight:       #fff;
@navbarBackground:                darken(@navbarBackgroundHighlight, 5%);
@navbarBorder:                    darken(@navbarBackground, 12%);
@navbarText:                      @disneyNavTxt;
@navbarLinkColor:                 @disneyNavTxt;
@navbarLinkColorHover:            @disneyNavLinkhover;
@navbarLinkColorActive:           #02456E;
@navbarLinkBackgroundHover:       transparent;
@navbarLinkBackgroundActive:      #02456E;
@navbarBrandColor:                @navbarLinkColor;
@navbarActiveColor:               #036AAA;

// Dropdowns
// --------------------------------------------------
@dropdownBackground:            @white;
@dropdownBorder:                rgba(0,0,0,.2);
@dropdownDividerTop:            #e5e5e5;
@dropdownDividerBottom:         @white;
@dropdownLinkColor:             @grayDark;
@dropdownLinkColorHover:        @white;
@dropdownLinkColorActive:       @white;
@dropdownLinkBackgroundActive:  @linkColor;
@dropdownLinkBackgroundHover:   @dropdownLinkBackgroundActive;

// Subnav
// --------------------------------------------------

// Force z-index behind top level dropdowns
.subnavbar{
  z-index: @zindexSubnavbar;
}

.subnavbar > .navbar-inner {
  background-color: #036AAA;
  border-color: #fff;
  min-height: 38px;
}

.subnavbar > .navbar-inner .nav > li > a {
  font-family: 'Lato',sans-serif;
  font-weight: 800;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  padding: 04px 14px 04px;
  margin: 4px 03px 4px;
}

.navbar-static-top {}


// Utilities
// --------------------------------------------------
.subnavbar>.navbar-inner .nav>li>a.vertical-rule {
  margin-right:0px;
  margin-left:0px;
  padding-left:0px;
  padding-right:0px;
  text-decoration:none;
}

.subnavbar>.navbar-inner .nav>li>a.disabled {
  color: SILVER;
  text-decoration:none;
}


.sub-nav-link {
  font-family: 'Lato',sans-serif;
  font-weight: 800;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  padding: 6px 14px 04px;
  margin: 4px 03px 4px;
  text-shadow: none;
  height:20px;
}

input.sub-nav-link {
  border: none;
  background: transparent;
}


.navbar-nav .logo, .navbar-nav .betalogo {
  padding: 0;
  margin-right: 10px;
  margin-top: 7px;
  img {
    width: 190px;
  }
}

header .navbar {
  background-color:white;
}


// Custom YAMM
// --------------------------------------------------
.dropdown-menu > li > .yamm-content {
  min-width:270px;
}

.dropdown-menu > li > .yamm-content a {
  display: block;
  padding: 3px 3px;
  margin:0px 0px;
  clear: both;
  line-height: 20px;
  white-space: nowrap;
  color:#000;
  text-decoration:none;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-menu > li > .yamm-content li {
  margin:0px;
  padding:0px;
  line-height:5px;
}

// Hover/Focus state
// --------------------------------------------------

.dropdown-menu > li > .yamm-content a:hover,
.dropdown-menu > li > .yamm-content a:focus,
.dropdown-submenu:hover > .yamm-content a,
.dropdown-submenu:focus > .yamm-content a {
  text-decoration: none;
  outline: 0;
  color: @dropdownLinkColorHover;
  background-color: @dropdownLinkBackgroundHover;
  color: #fff;
  background-color: #036AAA;
}

// hide hover if there is no content
.dropdown-menu > li > .yamm-content a.empty:hover,
.dropdown-menu > li > .yamm-content a.empty:focus,
.dropdown-submenu:hover > .yamm-content a.empty,
.dropdown-submenu:focus > .yamm-content a.empty {
  background-color: transparent;
}



// The dropdown menu (ul)
.dropdown-menu {
  > li > a {
    text-decoration:none;
  }
}


// USER PROFILE MENU
// Hover/Focus state
// --------------------------------------------------

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  color: @dropdownLinkColorHover;
  background-color: #036AAA;
}

.dropdown-menu .col-md-12,
.dropdown-submenu .col-md-12,
.dropdown-menu .col-md-9,
.dropdown-submenu .col-md-9,
.dropdown-menu .col-md-6,
.dropdown-submenu .col-md-6,
.dropdown-menu .col-md-3,
.dropdown-submenu .col-md-3 {
  padding-left:04px;
  padding-right:04px;
}


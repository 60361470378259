//
// Utility classes
// --------------------------------------------------

a.small-cap {
  font-variant:small-caps;
}

img  { max-width:100%; border:0px;}

.admin .user-only,
.not-admin .admin-only {
  display: none;
}
.admin-only-highlight {
  color:@admin-only-color;
  td { color: @admin-only-color }
}

.admin-only-highlight > .select2-container-multi{
  border:1px solid @admin-only-color;
}


// Quick floats
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}

// Toggling content
.hide {
  display: none;
}
.show {
  display: block;
}

.hidden, .hide {
  display: none;
}

// Visibility
.invisible {
  visibility: hidden;
}

.visHidden {
  visibility: hidden;
}

// For Affix plugin
.affix {
  position: fixed;
  margin:0px;
  top: 0;
  right: 0;
  left: 0;
  z-index:@zindexAffix;
}

.no_margins{
  margin:0px;
}


//
// Component animations from bootstrap
// --------------------------------------------------


.fade {
  opacity: 0;
  .transition(opacity .15s linear);
  &.in {
    opacity: 1;
  }
}

.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  .transition(height .35s ease);
  &.in {
    height: auto;
  }
}

//

.collapse-toggle {
    .no-select;
 margin-top:04px;
 display:block;
 text-decoration: none;
}

.collapse-toggle:hover {
  text-decoration: none;
  cursor: pointer;
}

.collapse-toggle:before {
  content: "\f0d7"; /*down*/
  text-decoration: none;
  font-family: FontAwesome;
  margin-right: 8px;
  display: inline;
}

.collapse-toggle.collapsed:before {
  content: "\f0da"; /*down*/
}

// used to line up titles for in page links.

.inpage_anchor {
  padding-top: 80px;
  margin-top: -80px;
}

#mediasection {
  .inpage_anchor
}

#medialist_video {
  .inpage_anchor
}


.loading-spinner-with-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(246, 246, 246);
  filter:alpha(opacity=60); /* IE */
  opacity: 0.6;
  min-height:50px;
  z-index: @zindexLoadingSpinner;
}

.loading-spinner-with-overlay:after {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 32px;
  height: 32px;
  background-image: url(/img/spinner.gif);
  content: '';
}

@media(max-width: 600px){
  .loading-spinner-with-overlay:after {
    top: 20%;
  }
}

.search-title .same-height{
  height:90px;
}

.loading-spinner {
  width: 32px;
  height: 32px;
  content: '';
  position: absolute;
  display: inline;
}

.loading-spinner.show {
  background-image: url(/img/spinner.gif);
}

// center icons in datatables
th.formats,
th.timing-sheet,
th.broadcast-schedule,
th.alerts,
td.formats,
td.timing-sheet,
td.broadcast-schedule,
td.alerts {
    text-align: center;
    vertical-align: center;
    width: 60px;
}

// remove margins
.no-margin{
  margin: 0 0 0 !important;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

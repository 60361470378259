// Tooltip
//
// All HTML headings, <h1> through <h6> are available.
//
// markup:
// <ul class="bs-docs-tooltip-examples">
//   <li><a href="#" data-toggle="tooltip" data-placement="top" data-original-title="Tooltip on top">Tooltip on top</a></li>
//   <li><a href="#" data-toggle="tooltip" data-placement="right" data-original-title="Tooltip on right">Tooltip on right</a></li>
//   <li><a href="#" data-toggle="tooltip" data-placement="bottom" data-original-title="Tooltip on bottom">Tooltip on bottom</a></li>//   <li><a href="#" data-toggle="tooltip" data-placement="left" data-original-title="Tooltip on left">Tooltip on left</a></li>
// </ul>
//
// Styleguide 15.0

.tooltip {
  font-size: @base-font-size;
  
  &.in {
    opacity: 1;
  }
  &.top {
    padding-bottom: 9px;
    
    .tooltip-arrow {
      border-top-color: @textColor;
      border-width: 9px 9px 0;
      bottom: 0;
      margin-left: -9px;
    }
  }
  &.right {
    .tooltip-arrow {
      border-right-color: @textColor;
      border-width: 9px 9px 9px 0;
      margin-top: -9px;
      left: -3px;
    }
  }
  &.bottom {
    padding-top: 8px;
    
    .tooltip-arrow {
      border-bottom-color: @textColor;
      border-width: 0 9px 9px;
      margin-left: -9px;
      top: -1px;
    }
  }
  &.left {
    .tooltip-arrow {
      border-left-color: @textColor;
      border-width: 9px 0 9px 9px;
      margin-top: -9px;
      right: -3px;
    }
  }
}

.tooltip-inner {
  background-color: @textColor;
  line-height: @base-font-size * 1.285;
  padding: 12px 12px;
  text-align: center;
  max-width: 183px;
  white-space:pre-wrap;
  .border-radius(6px);
}
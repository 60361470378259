.thumbnail-small,
.cumulus-thumbnail-small {
  left: 0;
  top: 0;
  padding: 10px;
  background-color: #fff;
  width:100px;
  position: relative;
  transition: 0.5s ease;
  -webkit-transition: 0.5s ease;
  -moz-transition: 0.5s ease;

}
.thumbnail-small:hover,
.cumulus-thumbnail-small:hover{
  border: solid 1px rgba(0,0,0,0.5);
  border-radius: 5px;
  -webkit-transform: scale(1.5,1.5);
  -moz-transform:scale(1.5,1.5);
  opacity: 1;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  z-index: @zindexImageThumbnail;

}

// Layout Module
// 
// Overview
// Typography, Buttons, Links
// 
// Styleguide 4.0

// Container
//
// Container (centered, fixed-width layouts)
// 
// markup:
// <div class="container">
// <p>Nullam quis risus eget urna mollis ornare vel eu leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam id dolor id nibh ultricies vehicula.</p>
// <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec ullamcorper nulla non metus auctor fringilla. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec ullamcorper nulla non metus auctor fringilla.</p>
// </div>
//
// Styleguide 4.1

// Container (centered, fixed-width layouts)
.container {
  .container-fixed();
  margin-top: 0px;
  padding-top: 0px;
  margin-bottom: 10px;
  padding-bottom: 0px;
}

// Container White
//
// Container (centered, fixed-width layouts)
// 
// markup:
// <div class="container">
// <p>Nullam quis risus eget urna mollis ornare vel eu leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam id dolor id nibh ultricies vehicula.</p>
// <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec ullamcorper nulla non metus auctor fringilla. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec ullamcorper nulla non metus auctor fringilla.</p>
// </div>
//
// Styleguide 4.1

// used to create white strips section on long pages.
.white-container {
  .container-fixed();
  background: white;
  margin-top: 0px;
  padding-top: 0px;
  margin-bottom: 10px;
  padding-bottom: 0px;

}

.row {
  margin-bottom: 10px;
}

.search-results {
  padding-top: 20px;
}

.row-section {
  margin-bottom: @baseLineHeight * 2;
}

.row-section h4 {
  margin-top: 0;
}

.striped-containers .container:nth-child(even) {
  background: white;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  .row {
    margin: 0 auto;
  }
}

@media (max-width: @screen-xs-max) {
  .striped-containers .container:nth-child(even) .row {
    width: 100%;
    margin: 0 -15px 0 -15px
  }
}

@media (min-width: @screen-sm) {
  .striped-containers .container:nth-child(even) .row {
    width: @container-sm;
    margin: 0 auto;
  }
}

@media (min-width: @screen-md) {
  .striped-containers .container:nth-child(even) .row {
    width: @container-md;
  }
}

@media (min-width: @screen-lg) {
  .striped-containers .container:nth-child(even) .row {
    width: @container-lg;
  }
}
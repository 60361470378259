.bootstrap-datetimepicker-widget {
    td span {
        width: @date-time-picker-width;
        height: @date-time-picker-height;
        line-height: @date-time-picker-line-height;
        margin: 0;
    }

    .btn {
        background-color: @grayLight;
        padding: 0;
    }

    td {
        height: auto;
        width: auto;
        line-height: 1;
    }

    a[data-action] {
        padding: 0;
    }

    a.btn :hover {
        background-color: darken(@btnBackground, 20%);
    }
}

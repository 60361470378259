.override-wrap {
  label {
    display:inline;
  }
}

.report-header {
  margin-bottom:15px;
  margin-top:25px;
  font-size:38px;
}

.statistic {
  padding:25px;
  background-color:#FFF;
  border: 1px solid transparent;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  margin-bottom:20px;
  margin-left:0px;
  text-align:center;

  .value {
    font-size:30px;
    color:#767676;
  }

  .name {
    margin-top:15px;
    font-size:18px;
    color:#999998;
    line-height:1.1em;
  }
}

.stat-header {
  padding:30px 0 0 0px;
}

@media(max-width:800px) {
  .statistic i {
    display:none;
  }
}

.order-assets-list .isci-column {
  width: 140px;
}

.order-assets-list .name-column {
  width: 670px;
}

.order-assets-list .terr-column {
  width: 80px;
}

.order-assets-list .lang-column {
  width: 80px;
}

.order-properties .property-item-label,
.title-list-item .property-item-label {
  vertical-align: top;
  text-align: right;
}

.order-properties .property-item-value,
.title-list-item .property-item-value {
  font-size: 17px;
}


/**
 * we do not what to include the vendor stylesheets when linting
 * so i had to create a seperate custom base less file that does
 * not include the vendor stylesheets. this created a new problem
 * in that we are calling varieables and mixins from bootstrap
 * within our less. so i had to copy this in to this file just
 * for linting.
*/

@import "tools/z-index";
@import "config/config";

@import "layout/layouts";
@import "modules/footer";
@import "base/base";
@import "modules/type";
@import "modules/login";
@import "modules/button";
@import "modules/button-groups";
@import "modules/datepicker";
@import "modules/app-nav";
@import "modules/pills-nav";
@import "modules/tabs";
@import "modules/steps";
@import "modules/quicklinks";
@import "modules/filter";
@import "modules/accordion";
@import "modules/collapse";
@import "modules/languagebar";
@import "base/tables";
@import "modules/duration_template";
@import "modules/notification";
@import "base/forms";
@import "modules/labels-badges";
@import "modules/modals";
@import "modules/popovers";
@import "modules/tooltip";
@import "modules/pagination";
@import "modules/revisions";
@import "modules/style";
@import "modules/chart";
@import "modules/cart";
@import "modules/orders";
@import "modules/cumulus";
@import "modules/close";
@import "modules/caret";
@import "modules/misc";
@import "modules/multi-column-list";
@import "modules/table";
@import "modules/utilities";
@import "modules/episode-numbers";
@import "modules/invitations";

@import "general/style";
@import "notifications/style";

// Has to be last to override when necessary
// Print CSS
@import "modules/print";

@import "tools/accordion";
@import "tools/base_colours";
@import "tools/print";
@import "tools/colours";
@import "tools/typography";
@import "tools/variables";

@import "mixins/lint";
@import "mixins/mixins";

@import "wysiwygStyles/style.less";

.checkbox label {
  display: inline;
}

.error_container {
  color: red;
}

[class*=span] {
  float: left;
  min-height: 1px;
  margin-left: 20px;
}

label {
  cursor: pointer;
}

.extra-margin-left-small {
  margin-left: 5px !important;
}
.extra-margin-right-small {
  margin-right: 5px !important;
}
.extra-margin-left-large {
  margin-left: 20px !important;
}
.extra-margin-right-large {
  margin-right: 20px !important;
}

.extra-padding-left-small {
  padding-left: 10px !important;
}
.extra-padding-right-small {
  padding-right: 10px !important;
}
.extra-padding-left-large {
  padding-left: 20px !important;
}
.extra-padding-right-large {
  padding-right: 20px !important;
}

.artwork_thumbnails img {
  width: 100%;
  max-width: 80px;
}

.btn-action-table {
  min-width: 100px !important;
}

.centered_flex {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.bootstrap-datetimepicker-widget td span {
  width: 54px !important;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #d6d6d6 !important;
}

/* help page styles */
.help-topics li {
  margin: 10px 0;
}

// Vertical button groups
//
// All HTML headings, <h1> through <h6> are available.
//
// markup:
// <div class="btn-group btn-group-vertical">
//   <button type="button" class="btn"><i class="icon-align-left"></i></button>
//   <button type="button" class="btn"><i class="icon-align-center"></i></button>
//   <button type="button" class="btn"><i class="icon-align-right"></i></button>
//   <button type="button" class="btn"><i class="icon-align-justify"></i></button>
// </div>
//
// Styleguide 7.8.3
.btn-group-vertical {
  display: inline-block; // makes buttons only take up the width they need
}
.btn-group-vertical > .btn {
  display: block;
  float: none;
  max-width: 100%;
  .border-radius(0);
}
.btn-group-vertical > .btn + .btn {
  margin-left: 0;
  margin-top: -1px;
}
.btn-group-vertical > .btn:first-child {
  .border-radius(@baseBorderRadius @baseBorderRadius 0 0);
}
.btn-group-vertical > .btn:last-child {
  .border-radius(0 0 @baseBorderRadius @baseBorderRadius);
}
.btn-group-vertical > .btn-large:first-child {
  .border-radius(@borderRadiusLarge @borderRadiusLarge 0 0);
}
.btn-group-vertical > .btn-large:last-child {
  .border-radius(0 0 @borderRadiusLarge @borderRadiusLarge);
}

.a_revision {
  max-width:400px;
  padding:0px 1px;/*new*/
}


.a_revision_content {
  border-bottom:1px solid #E6E6E6;
  padding-bottom:2px;
}

.revision_content {
  overflow:auto;/* prevents div margin collapse */
  padding:1px 1px;/* without padding the div margins collapse and background disappears */
}


.a_revision_context {
  /* no padding because want grey to appear like one solid chunk */
  border:1px solid #b9b9b9;
  border-collapse: collapse;
}

.revision_context {
  overflow:auto;/* prevents div margin collapse */
  margin:0px;
  background-color:#E6E6E6;
  padding:1px 1px;/*without padding the div margins collapse and background disappears*/
}


.revision_after_label,
.revision_before_label,
.revision_field_label {
  font-weight: bold;
}

.revision_field_label {
  text-align: right;
}

.revision_after_block {
  padding-bottom:0px;
}

.a_revision:last-child {
  margin-bottom:5px;
}

.a_revision_context:nth-child(n+2) {
  margin-bottom: -1px;
}

.a_revision_content:nth-child(n+2) {
  padding-top:2px;
}

.a_revision:first-child .revision_content {
  padding-top:0px;
}

.a_revision:last-child .revision_context {
  padding-bottom:5px;
}

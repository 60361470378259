//
// Forms
// --------------------------------------------------

// GENERAL STYLES
// --------------

// Make all forms have space below them

label[required]:after {
    content: "*";
}

.select2-container {
    margin-bottom: @baseLineHeight / 2 !important;
}

.select2-container + select {
    display: none !important;
}

.form-group .error .control-label {
    color: @errorColor;
}

.form-group .error input {
    border-color: @errorColor;
    color: #000;
    &:focus {
        border-color: darken(@errorColor, 10%);
        @shadow: 0 0 6px darken(@errorColor, 20%);
        .box-shadow(@shadow);
    }

}

// HTML5 invalid states
// Shares styles with the .form-group .error above
input:focus:invalid,
textarea:focus:invalid,
select:focus:invalid {
    &:focus {
        @shadow: 0 0 6px darken(#ee5f5b, 20%);
    }
}

// Re-hide hidden elements due to specifity
.hide {
    display: none;
}

// INPUT ICONS
// ----------------------
.form-group {
    position: relative;

    > .input-icon {
        position: absolute;
        top: 2px;
        right: 2px;
        line-height: 37px;
        vertical-align: middle;
        font-size: @base-font-size * 1.428; /* 20px */
        color: desaturate(lighten(@textColor, 45%), 15%);
        background-color: #fff;
        padding: 0 10px;
        .border-radius(6px);
    }

    input:focus + .input-icon {
        color: @textColor;
    }

    // Icon Sizes
    // --------------------------------------------------

    // Huge
    &.huge {
        > .input-icon {
            line-height: 49px;
        }
    }

    //Large
    &.large {
        > .input-icon {
            line-height: 41px;
        }
    }

    // Small
    &.small {
        > .input-icon {
            font-size: @base-font-size * 1.142; /* 16px */
            line-height: 30px;
        }
    }

    // Icon Colors
    // --------------------------------------------------
    &.disabled {
        > .input-icon, input + .input-icon {
            color: mix(@gray, white, 40%);
            background-color: mix(@gray, white, 10%);
        }
    }

}

// Disabled state
input[disabled],
input[readonly],
textarea[disabled],
textarea[readonly] {
    background-color: mix(@gray, white, 10%);
    border-color: mix(@gray, white, 40%);
    color: #777;
    cursor: default;
}

.form-control {
    &[disabled],
    &[readonly] {
        background-color: #ebebeb;
        border-color: #dddddd;
        color: #777;
        cursor: not-allowed;
    }
}

.datetimepicker .form-control[readonly] {
    background-color: white;
}

// Textarea
textarea {
    height: auto;
    font-size: @base-font-size; /* 15px */
    line-height: 1.25;
    padding: 5px 11px;
    text-indent: 0;

    .row-fluid & {
        height: auto;
        width: 100% !important;
    }
}

textarea[class*="col-md-"] {
    width: 100% !important;
    .box-sizing(border-box);
}

label.inline {
    display: inline;
    margin-right: 3px;
    margin-left: 3px;
}

.delivery-methods label {
    display: inline-block;
    padding-top: 5px;
    margin-bottom: 0;
    vertical-align: middle;
}

.copy-to-season, .copy-to-program, .copy-to-order {
    max-width: 280px;
}

.height0 {
    height: 0px;
}

//.input-condition
//
// Follows a regular text input to provide a toggle for it
.input-condition {
    margin-top: -16px;
}

.input-condition label {
    font-size: 12px;
}

.lowered-buttons {
    padding-top: 19px;
}

//Search section specifics

.search-title .search-submit {
    margin-top: 21px;
}

@media (min-width: 768px) {
    .form-inline {
        .form-group .select2-container {
            display: inline-block;
        }
    }
}

button.unstyled {
    font-weight: bold;
    line-height: 20px;
    color: #000;
    opacity: 0.2;
    filter: alpha(opacity=20);
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    &:hover,
    &focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
        opacity: 0.4;
        filter: alpha(opacity=40);
    }
}

// List of checkboxes.
//
// Gets a nice looking list of checkboxes.
//
// markup:
// <ul class="checkable-list">
//   <li>
//     <input id="simple_output_70" type="checkbox" value="70">
//     <label for="simple_output_70">Label Text</label>
//   </li>
//   <li>
//     <input id="simple_output_71" type="checkbox" value="70">
//     <label for="simple_output_71">Label Text 2</label>
//   </li>
// </ul>

//
// Styleguide 1.1.1

.checkable-label {
    display: inline;
    padding-left: 5px;
    font-weight: normal;
}

ul.checkable-list {
    .unstyled-list();
    margin-bottom: 6px;
    margin-left: 0px;
}

ul.checkable-list > li > input {
    .pull-left;
    margin: 0;
}

ul.checkable-list > li > input[type="checkbox"] {
    font-size: 16px;
}

ul.checkable-list > li {
    padding: 3px 0;
    margin-left: 1px;
}

ul.checkable-list > li > label {
    padding: 3px;
    .checkable-label;
}

.checkable-list.inline li {
    margin-right: 12px;
    padding-top: 0;
    padding-bottom: 0;
}

ul.checkable-list.inline > li > input {
    margin-left: 10px;
    padding: 0;
}

ul.checkable-list.inline > li {
    padding-right: 7px;
}

ul.checkable-list.inline > li > label {
    margin: 0;
    padding: 0;
    padding-left: 4px;
}

ul.checkable-list.inline > li:first-child > input {
    margin-left: 0px;
}

.checkable-group {
    margin-bottom: 6px;
}

.checkable-group label {
    display: inline;
    padding-left: 2px;
}

.checkable-group input {
    margin-top: 0;
}

div.right-arrow {
    display: none;
}

div.down-arrow {
    display: block;
}

.closed div.right-arrow {
    display: block;
}

.closed div.down-arrow {
    display: none;
}

.hide_empty_circles_container {
    display: inline-block;
}

#organization_wrapper,
#deals_datatable_wrapper,
.deals_datatable_form {
    position: relative;
}

.datatables_processing, .datatables-error-div-container {
    position: absolute;
    top: 100px;
    left: 50%;
    margin-left: -55px;
    width: 0; /* we don't want to steal click events from datatables */
    height: 0; /* we don't want to steal click events from datatables */
}

.datatables-error-div-container {
    margin-left: -135px;
}

.dataTables_processing, .datatables-error-div {
    width: 110px;
    padding: 15px 20px;
    background: white;
    text-align: center;
    border: 1px solid @black;
    font-size: 20px;
    color: @blueDark;
}

.datatables-error-div {
    color: @errorColor;
    width: 270px;
}

// Admin Only Fields
.adminOnly {
    color: @admin-only-color;
}

// datatables search
input.search {
    background-image: url('/img/small-search.png');
    background-repeat: no-repeat;
    background-position: center right;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
}

// datatables search
.lt-ie9 input.search {
    padding-right: 28px;
    padding-bottom: 0px;
    padding-top: 4px;
}

#s2id_sale_status_id {
    min-width: 120px;
}

input[type=file] {
    line-height: 13px;
}

.toolbar-right-column li {
    float: right;
    clear: right;
}

.btn.active:hover {
    cursor: default;
}

.toggle-selector[data-toggle-state="on"] .btn[data-toggle="on"] {
    background-color: #5bb75b;
}

.toggle-selector[data-toggle-state="off"] .btn[data-toggle="off"] {
    background-color: #da4f49;
}

// Add space to the bottom of field sets:
fieldset {
    margin-bottom: 10px;
}

.table-pagination,
.table-search {
    div {
        float :none !important;
    }
    div.float-right {
        float: right !important;
    }
    div.float-left {
        float: left !important;
    }
}

.table-pagination {
    display: none;
}

.table-pagination.bottom {
    margin-top: 15px;
}

div.dataTables_filter input {
    margin-left: 0px !important;
}

.box-filters {
    h3 {
        margin-bottom: 20px;   
    }
    padding: 2px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
}

.subtitle {
    color: #19b3cc;
}

.select2-container a.error,
input.error,
select.error,
textarea.error {
    border: 2px solid #ff8484 !important;
}

.select2-container a.valid,
input.valid,
select.valid,
textarea.valid {
    border: 1px solid #ccc !important;
}

.pagination > .disabled > span, 
.pagination > .disabled > span:hover, 
.pagination > .disabled > span:focus, 
.pagination > .disabled > a, 
.pagination > .disabled > a:hover, 
.pagination > .disabled > a:focus {
    color: #c6c6c6 !important;
}

.pagination > .active > a, 
.pagination > .active > span,
.pagination > .active > a:hover, 
.pagination > .active > span:hover, 
.pagination > .active > a:focus, 
.pagination > .active > span:focus {
    color: white !important;
}

.pagination > li > a, 
.pagination > li > span {
    color: #3577bb !important;
}

.row-disabled {
    a {
        color: inherit;
    }
    color: #c2c2c2;
}

.note-editor.note-frame .note-editing-area .note-editable[contenteditable="false"] {
    background-color: #ebebeb !important;
}
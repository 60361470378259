// Buttons
//
// All HTML headings, <h1> through <h6> are available.
//
// markup:
// <table class="table table-bordered table-striped">
//   <thead>
//     <tr>
//       <th>Button</th>
//       <th>class=""</th>
//       <th>Description</th>
//     </tr>
//   </thead>
//   <tbody>
//     <tr>
//       <td><button type="button" class="btn">Default</button></td>
//       <td><code>btn</code></td>
//       <td>Standard gray button with gradient</td>
//     </tr>
//     <tr>
//       <td><button type="button" class="btn btn-default">Default</button></td>
//       <td><code>btn btn-default</code></td>
//       <td>Provides extra visual weight and identifies the default action in a set of buttons</td>
//     </tr>
//     <tr>
//       <td><button type="button" class="btn btn-primary">Primary</button></td>
//       <td><code>btn btn-primary</code></td>
//       <td>Provides extra visual weight and identifies the primary action in a set of buttons</td>
//     </tr>
//     <tr>
//       <td><button type="button" class="btn btn-info">Info</button></td>
//       <td><code>btn btn-info</code></td>
//       <td>Used as an alternative to the default styles</td>
//     </tr>
//     <tr>
//       <td><button type="button" class="btn btn-success">Success</button></td>
//       <td><code>btn btn-success</code></td>
//       <td>Indicates a successful or positive action</td>
//     </tr>
//     <tr>
//       <td><button type="button" class="btn btn-warning">Warning</button></td>
//       <td><code>btn btn-warning</code></td>
//       <td>Indicates caution should be taken with this action</td>
//     </tr>
//     <tr>
//       <td><button type="button" class="btn btn-danger">Danger</button></td>
//       <td><code>btn btn-danger</code></td>
//       <td>Indicates a dangerous or potentially negative action</td>
//     </tr>
//     <tr>
//       <td><button type="button" class="btn btn-inverse">Inverse</button></td>
//       <td><code>btn btn-inverse</code></td>
//       <td>Alternate dark gray button, not tied to a semantic action or use</td>
//     </tr>
//     <tr>
//       <td><button type="button" class="btn btn-link">Link</button></td>
//       <td><code>btn btn-link</code></td>
//       <td>Deemphasize a button by making it look like a link while maintaining button behavior</td>
//     </tr>
//   </tbody>
// </table>
//
// Styleguide 7

// Core
.btn {
    padding: 7px 12px;
    border: 0;
    color: #fff;
    margin: 2px;

    &:hover,
    &:focus {
        background-color: @btnBackgroundHighlight;
    }

    // Disabled state
    &.disabled,
    &[disabled] {
        cursor: pointer;
    }

    // Disabled state
    &.disabled:hover,
    &[disabled]:hover {
        cursor: pointer;
        background-image: none;
        background-color: #eee;
        border-color: #bbb;
        color: #bbb;

    }

}

// Button Sizes
//
// All HTML headings, <h1> through <h6> are available.
//
// markup:
// <div class="bs-docs-example">
//   <p>
//     <button type="button" class="btn btn-large btn-primary">Large button</button>
//     <button type="button" class="btn btn-large">Large button</button>
//   </p>
//   <p>
//     <button type="button" class="btn btn-primary">Default button</button>
//     <button type="button" class="btn">Default button</button>
//   </p>
//   <p>
//     <button type="button" class="btn btn-small btn-primary">Small button</button>
//     <button type="button" class="btn btn-small">Small button</button>
//   </p>
//   <p>
//     <button type="button" class="btn btn-mini btn-primary">Mini button</button>
//     <button type="button" class="btn btn-mini">Mini button</button>
//   </p>
// </div>
//
// <div class="bs-docs-example">
//   <p>
//     <button type="button" class="btn btn-large btn-default">Large button</button>
//     <button type="button" class="btn btn-large">Large button</button>
//   </p>
//   <p>
//     <button type="button" class="btn btn-default">Default button</button>
//     <button type="button" class="btn">Default button</button>
//   </p>
//   <p>
//     <button type="button" class="btn btn-small btn-default">Small button</button>
//     <button type="button" class="btn btn-small">Small button</button>
//   </p>
//   <p>
//     <button type="button" class="btn btn-mini btn-default">Mini button</button>
//     <button type="button" class="btn btn-mini">Mini button</button>
//   </p>
// </div>
// Styleguide 7.1

// Block Level Buttons
//
// All HTML headings, <h1> through <h6> are available.
//
// markup:
// <div class="well" style="max-width: 400px; margin: 0 auto 10px;">
//   <button type="button" class="btn btn-large btn-block btn-primary">Block level button</button>
//   <button type="button" class="btn btn-large btn-block btn-default">Block level button</button>
//   <button type="button" class="btn btn-large btn-block">Block level button</button>
// </div>
//
// Styleguide 7.3

// Disables
//
// All HTML headings, <h1> through <h6> are available.
//
// markup:
// <a href="#" class="btn btn-large btn-primary disabled">Primary link</a>
// <a href="#" class="btn btn-large btn-default disabled">Primary link</a>
// <a href="#" class="btn btn-large disabled">Link</a>
//
// Styleguide 7.4

// Alternate buttons
// --------------------------------------------------

// Provide *some* extra contrast for those who can get it
.btn-primary.active,
.btn-default.active,
.btn-warning.active,
.btn-danger.active,
.btn-success.active,
.btn-info.active,
.btn-inverse.active {
    color: rgba(255, 255, 255, .75);
}

// Preset Button
//
// All HTML headings, <h1> through <h6> are available.
//
// markup:
// <button type="button" class="btn btn-large btn-default disabled" disabled="disabled">Primary button</button>
//
// <button type="button" class="btn btn-large btn-primary disabled" disabled="disabled">Primary button</button>
// <button type="button" class="btn btn-large" disabled>Button</button>
//
// Styleguide 7.5

.btn-default {
    background-color: @btnBackground;
    color: @btnColor;
    min-width: 120px;
}

.btn-mini {
    padding: 4px 8px;
    font-size: 12px;
    text-shadow: none;
    min-width: 100px;
    text-align: left;
    margin: 1px;
    i {
        display: inline-block;
        width: 14px;
        text-align: center;
    }
}

.btn-default.btn-cart, .btn-default.inCart {
    min-width: 120px;
}

.nav > li > a.btn:hover,
.filter li a:hover,
.btn-default:hover,
.nav > li > a.btn:focus,
.filter li a:focus,
.btn-default:focus {
    background-color: darken(@btnBackground, 20%);
    color: @btnColor;
}

.nav > li > a.btn:active,
.filter li a:active,
.btn-default:active {
    background-color: @btnBackgroundHighlight;
    color: @btnColor;
}

.btn-default[disabled],
.btn-default[disabled]:hover {
    background-color: @disabledBtnBackground;
    color: @disabledBtnColor;
    opacity: 0.65;
    filter: alpha(opacity=65);
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    opacity: 0.65;
    filter: alpha(opacity=65);
}

.btn-primary {
    background-color: @btnPrimaryBackground;
}

.nav > li.active > a.btn:hover,
.filter li.active a:hover,
.btn-primary:focus,
.btn-primary:hover {
    background-color: @btnPrimaryBackgroundHighlight;
}

// One class, Multiple tags
//
// All HTML headings, <h1> through <h6> are available.
//
// markup:
// <a class="btn" href="">Link</a>
// <button class="btn" type="submit">Button</button>
// <input class="btn" type="button" value="Input">
// <input class="btn" type="submit" value="Submit">
//
// Styleguide 7.6

.btn-call-action {
    background-color: @CallToActionBgColors;
    color: @CallToActionColors;
    padding: 11px 19px;
    font-size: 17.5px;
}

.btn-call-action:hover {
    background-color: @CallToActionColorsHover;
    color: @CallToActionColors;
}

.btn-call-action:focus {
    background-color: @CallToActionColorsHover;
    color: @CallToActionColors;
}

//
// Buttons
// --------------------------------------------------

// Base styles
// --------------------------------------------------

// This is used to style the + button used
// add file type to dropdowns
button.tool-tip {
    color: #2697DD;
    text-decoration: none;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

button.tool-tip:hover {
    color: #000;
}

.btn-file {
    background-color: @btnPrimaryBackground;
}

.btn-file:hover {
    background-color: @btnPrimaryBackgroundHighlight;
    color: @btnColor;
}

// Override Bootstrap
.btn {
    vertical-align: middle;
    text-shadow: none;
    background-image: none;
    box-shadow: none;
}

.btn.active {
    color: rgba(255, 255, 255, 0.75);
    background-color: #999;
}

.btn.disabled, .btn[disabled] {
    cursor: default;
    background-image: none;
    background-color: #bebebe;
    border-color: #5f5f5f;
    color: #5f5f5f;
}

.btn.disabled:hover, .btn[disabled]:hover {
    cursor: default;
}

.btn-small {
    border: 1px solid #999;
    background-color: transparent;
    margin: 02px;
    margin-bottom: 06px;
    text-wrap: none;
    text-align: center;
    display: block;
    min-width: 100px;
}

a.btn-small {
    color: #000;
    text-decoration: none;
}

.btn-small:after {
    content: "\A";
    white-space: pre;
}

.btn-small:hover {
    background-color: #d6d6d6;
}

.btn-login {
    width: 300px !important;
}

.btn-clear-file {
    .btn-default;
}

.btn-aspera{
    .btn-primary;
    background-image: url(http://downloads.asperasoft.com/images/pic_th/icon_connect.png);
    background-size: 30px;
    background-position: 0px 5px;
    background-repeat: no-repeat;
    padding-left: 30px;
}

@btn-download-color: @btn-primary-color;
@btn-download-bg: @btn-primary-bg;
@btn-download-border: @btn-primary-border;

.btn-download {
    .button-variant(@btn-download-color; @btn-download-bg; @btn-download-border);
}

// order styles
.cart-row {
    background-color:@grayLighter;
    border-top:1px solid @grayLight;
    margin-bottom:0px;
    position:relative;
    padding: 8px 5px;
}

.cart-row > .row {
    // margin-bottom: 0;
}

.cart-row > div{
    // padding:0px;
    // display:inline-block;
    // top: -3px;
}
.cart-row div ul{
    margin:0px;
}
.cart-row .name-col {
    width:350px; 
    position: relative; 
}
.cart-row .remove-col {
    width:100px; 
    position: relative; 
}
.cart-row .remove-col a{
    cursor:pointer;
}

.cart-row .feature-col {
    width:145px;
}
.cart-row .asset-col {  
    width:350px;
    top: 0px;
}

.asset_cart .asset_category_list{
    width: 100%;
    color: @base-link-color;
    margin-left: 0px;
    border:1px solid @grayLight;
}
.asset_cart  .asset_category_list td{
    border-bottom:1px solid @grayLight;
    padding: 7px 5px;
    list-style:none;
}
.asset_cart  .asset_category_list td label{
    white-space:nowrap;
}

.asset_category_list td label {
    margin-bottom: 0;
}
.asset_category_list td input[type=radio] {
    margin-top: 0;
}

.asset_cart  .asset_category_list .active{
    background-color: #2595dd;
    color: @white;
}

.asset_cart .asset-note p { 
    float: right;
    margin: 0;
}
.asset_cart .asset-note {
    /*width: 384px;*/
}

.cart-row-metadata {
    background-color: @disneyBg;
}

.cart-row-metadata {
    padding-top: 10px;
    padding-bottom: 10px;
}

.cart-row-metadata legend {
    font-size: 16px;
}


.cart-row-metadata .checkable-list.inline li {
    margin-right: 0;
}

.cart-row-metadata textarea {
    font-size: 12px;
    line-height: 1;
    padding: 3px 5px;
    width: auto;
}

.cart-row-metadata select {
    width: 82px;
}

.cart-row-options .btn-add-spec {
    margin-right: 18px;
    display: inline-block;
}

.horizontal-form .control-group {
    margin-bottom: 0;
}

.localization-note {
    margin-top: -6px
}

.row-tight {
    margin-bottom: 0;
} 

.row.title-metadata {
    margin-left: -15px;
}

.cart-title-header input {
    margin-bottom: 0;
}

.title-metadata input[type=text].datepicker {
    background: #fff url(/img/calendar.png) no-repeat 98px 1px;
    width: 114px;
}

.cart-title-name {
    font-size: 18px;
}

 .cart-row .span.pull-right:last-child {
    padding-right: 4px;
 }

 .title-spec-presets label {
    font-weight: bold;
    line-height: 1.7;
}

 .cart-row-metadata {
    border-color: #ddd;
 }

 .cart-row-metadata.title-spec-presets {
    border-color: @grayLight;
 }

 .metadata-spec-validation {
    position: absolute;
    bottom: 0;
    right: 6px;
 }

 .btn-mini.pull-right[data-role="cart-removeSpec"] {
    margin-top: 21px;
 }
 
 [data-disabled-by-preset],
 [data-disabled-by-preset] + label {
    color: #888;
 }
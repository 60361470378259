// Tables
// --------------------------------------------------
@tableBackground: transparent; // overall background-color
@tableBackgroundAccent: #f6f6f6; // for striping
@tableBackgroundHover: #f5f5f5; // for hover
@tableBorder: #ddd; // table and cell border

.table > thead > tr > th {
    vertical-align: top;
}

table {
    font-size: 14px;
}

.table {
    // Cells
    th,
    td {
        padding: 6px;
        &.text-center {
            text-align: center;
        }
        &.text-right {
            text-align: right;
        }
    }
}

// TABLE BACKGROUNDS
// --------------------------------------------------
// Exact selectors below required to override .table-striped

// Hover states for .table-hover
.table-hover tbody tr {
    &.success:hover > td {
        background-color: darken(@successBackground, 5%);
    }
    &.error:hover > td {
        background-color: darken(@errorBackground, 5%);
    }
    &.warning:hover > td {
        background-color: darken(@warningBackground, 5%);
    }
    &.info:hover > td {
        background-color: darken(@infoBackground, 5%);
    }
}

.dataTables_wrapper
.dataTables_info,
div.dataTables_info {
    padding-bottom: 0;
    padding-top: 0;
    float: right;
}

table.table-striped {
    margin-top: 10px;
    margin-bottom: 15px !important;
}

table.table-striped tfoot,
table.table-striped thead {
    background-color: #C6E9FF;
    color: #283434;
}

table.table-striped tfoot th,
table.table-striped thead th {
    border-right: 1px solid #77C0EE;
    border-bottom: 1px solid #77C0EE;
    color: #283434;
}

table.table-striped tfoot th:last-child,
table.table-striped thead th:last-child {
    border-right: 1px solid #C6E9FF;
}

table.table-striped tr.even {
    background-color: #fff;
}

table.table-striped tr.odd {
    background-color: @tableBackgroundAccent;
}

table.table-striped tr.asset.even,
table.table-striped tr.asset.odd {
    -webkit-transition: background-color 75ms linear;
    -moz-transition: background-color 75ms linear;
    -o-transition: background-color 75ms linear;
    -ms-transition: background-color 75ms linear;
    transition: background-color 75ms linear;
}

table.table-striped tr.even td.connected {
    background-color: #eee;
}

table.table-striped tr.odd td.connected {
    background-color: #ddd;
}

table.table-striped tr {
    border-top: 0;
}

table.table-striped tbody > tr td,
table.table-striped tbody > tr td,
table.table-striped tbody > tr.even td,
table.table-striped tbody > tr.odd td {
    background-color: transparent;
}

/*
This is a copy of some of the contents of the
dt_bootstrap.css. for some reason ie is not
including the file.
*/
table.table thead .sorting,
table.table thead .sorting_asc,
table.table thead .sorting_desc,
table.table thead .sorting_asc_disabled,
table.table thead .sorting_desc_disabled {
    cursor: pointer;
    *cursor: hand;
    padding-right: 15px;
}

table.table thead .sorting {
    background: url('/img/sort_both.png') no-repeat center right;
}

table.table thead .sorting_asc {
    background: url('/img/sort_asc.png') no-repeat center right;
}

table.table thead .sorting_desc {
    background: url('/img/sort_desc.png') no-repeat center right;
}

table.table thead .sorting_asc_disabled {
    background: url('/img/sort_asc_disabled.png') no-repeat center right;
}

table.table thead .sorting_desc_disabled {
    background: url('/img/sort_desc_disabled.png') no-repeat center right;
}

table.table.sortable thead th a {
    display:block;
    text-decoration: none;
    color: #000;
}

.dataTables_wrapper {
    margin-bottom: 40px;
    display: block;
    clear: both;
}

//this is code to put the tvpgr-sub info absolutely positioned

td.syndication-ratings .rating-tvpgr-sub ul {
    display: inline;
    width: 100px;
}

td.syndication-ratings label {
    margin-right: 8px
}

td .rating-tvpgr-sub {
    width: 300px;
}

//datatables.js is making the product-windows tables quite narrow for some reason. cannot find why. this should be removed when new datatables is implemented
#product-windows table {
    width: 100% !important;
}

//property-list-item tables (used in meatadata and admin views primarily)
table.table-property-list-items {
    margin-top: 0;
    margin-bottom: @baseLineHeight * 2;
}

table.table-property-list-items {
    border-bottom: 1px solid #ddd;
}

table.table-property-list-items tbody:empty {
    border-bottom: 1px solid #ddd;
    margin-bottom: 0 !important;
}

table.table-spaced td {
    padding: 6px;
}

.altheader {
    background-color: #ADDEFF;

}

.alt_left {
    width: 140px;
    border-left: 1px solid #ccc;
}

.alt_right {
    width: 140px;
    border-right: 1px solid #ccc;
}

tr.new-row {
    // don't care about previous rules, this row is in the process of being added, make it green and bold so it sticks out
    font-weight: bold !important;
    background-color: @successBackground !important;
    color: @successColor !important;
    a {
        color: @successColor !important;
    }
}

tr.deleting {
    // don't care about previous rules, this row is in the process of being deleted, make it red and strikeout the text
    text-decoration: line-through !important;
    background-color: @errorBackground !important;
    color: @errorText !important;
    a {
        color: @errorText !important;
    }
}

td.runtimes {
    width: 85px;
    .form-group {
        width: 100%;
    }
}

td.cell-with-slugs .name, td.artwork_display_name .name{
  margin-bottom: 25px;
}

//
// Footer
// --------------------------------------------------
@footer-color: #7f8792;
@footer-background-color: #011e30;
@footer-title-color: #cbcfda;

// Footer
// -------------------------
footer {
    background-color: @footer-background-color;
    color: @footer-color;
    font-size: 15px;
    padding: 0;
    padding-top: 45px;
    margin-top: 0px;
    padding-bottom: 80px;

    // Footer Links
    a {
        color: @footer-color;
        font-weight: normal;
        font-size: 14px;
        text-decoration: none;
    }

    a:hover {
        color: @footer-title-color;
        text-decoration: none;
        border-bottom: 1px solid @footer-title-color;
    }

    // Footer Header
    h6 {
        font-size: 18px;
        text-transform: none;
        line-height: 32px;
    }

    // Footer Copyright Notice
    .copyright {
        text-transform: uppercase;
        font-variant: small-caps;
        font-size: 12px;
        font-weight: 800;
    }

    //Footer List
    li {
        line-height: 16px;
    }

}

// Footer Title
.footer-title {
    color: @footer-title-color;
}

//
// Variables
// --------------------------------------------------

@notificationColor:        #fff;
@notificationPadding:     0px;
@baseLineHeight:        20px;
@baseBorderRadius:      4px;


// Form states and alerts
// --------------------------------------------------
@errorColor:			#ec4444;
@warningColor:			#FF9900;
@noticeColor:			#3a87ad;
@successColor:			#468847;

// Form states and alerts
// -------------------------
@warningText:             #c09853;
@warningBackground:       #fcf8e3;
@warningBorder:           darken(spin(@warningBackground, -10), 3%);

@errorText:               #fff;
@errorBackground:         @errorColor;
@errorBorder:             darken(spin(@errorBackground, -10), 3%);

@successText:             #468847;
@successBackground:       #dff0d8;
@successBorder:           darken(spin(@successBackground, -10), 5%);

@infoText:                #3a87ad;
@infoBackground:          #d9edf7;
@infoBorder:              darken(spin(@infoBackground, -10), 7%);








// Notification
// --------------------------------------------------

.header_notification {
	padding:@notificationPadding;
	color:@notificationColor;
}

.infoContainer {
	display: inline;
	margin-top: 9px;
	margin-left: 20px;
}

.failure.infoContainer {
	color: @errorColor;
}

#header_notifications + .container{
	margin-top:0px;
}

#header_notifications {
	margin-bottom:0px;
}

#header_notifications .inline-help,
#header_notifications .error {
	color:@notificationColor;
}

.notification-control {
	position: absolute;
	top: 40%;
	left: 15px;
	width: 20px;
	height: 20px;
	margin-top: -16px;
	font-size: 20px;
	font-weight: 100;
	line-height: 20px;
	color: @notificationColor;
	text-align: center;
}

.notification-control.right {
left: auto;
right: 15px;
}



// Notification Types
// --------------------------------------------------

a.notification i.error {
	color:@errorColor;
}

a.notification i.warning {
	color:@warningColor;
}

a.notification i.notice {
	color:@noticeColor;
}

a.notification i.success {
	color:@successColor;
}

span.notification_related {
	display:block;
}



// Alerts
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.alert {
  padding: 0px;
  margin-bottom: @baseLineHeight;
  text-shadow: 0 1px 0 rgba(255,255,255,.5);
  background-color: @warningBackground;
  border: 1px solid @warningBorder;
  .border-radius(@baseBorderRadius);
}

.alert h4 {
  // Specified for the h4 to prevent conflicts of changing @headingsColor
  color: @warningText;
}
.alert h4 {
  margin: 0;
}

// Adjust close link position
.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  line-height: @baseLineHeight;
}

.close-alert {
	position: relative;
	top: -2px;
	right: -19px;
	line-height: @baseLineHeight;
	font-size: 1.5em;
	text-shadow: 0px 2px 1px #ffffff;
	background-color: transparent;
	border: none;
	color: #000000;
}

// Alternate styles
// --------------------------------------------------

.alert-success {
  background-color: @successBackground;
  border-color: @successBorder;
  color: @successText;
}
.alert-success h4 {
  color: @successText;
}

.alert-warning {
  background-color: @warningBackground;
  border-color: @warningBorder;
  color: @warningText;
}
.alert-warning h4 {
  color: @warningText;
}

.alert-danger,
.alert-error {
  background-color: @errorBackground;
  border-color: @errorBorder;
  color: @errorText;
}
.alert-danger h4,
.alert-error h4 {
  color: @errorText;
}
.alert-info {
  background-color: @infoBackground;
  border-color: @infoBorder;
  color: @infoText;
}
.alert-info h4 {
  color: @infoText;
}


// Block alerts
// --------------------------------------------------

.alert-block {
  padding-top: 14px;
  padding-bottom: 14px;
}
.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}
.alert-block p + p {
  margin-top: 5px;
}

.alert{
	border-radius: 0;
}

.alert .close {
	margin-left:20px;
	line-height: 20px;
	opacity: 1;
}

div.alert-error {
	color:@notificationColor;
	text-shadow:0 1px 0 rgba(0, 0, 0, 0.5);	
}

.inline-help,
.error {
	color:@errorColor;
	line-height: 2;
}

.val_error {
	margin-top:2px; //requested login page..
	font-size:12px;
	clear:left;
}

.previewInfo {
	margin-top:04px;
	font-weight:bold;
}

.aspera-box {
	height: 100px;
}

.aspera-logo-box {
	margin-left: 30px;
	float: left;
	top: 50%;
	position: relative;
	transform: translateY(-50%);
}

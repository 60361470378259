//
// Variables
// --------------------------------------------------

@baseLineHeight:        20px;
@bodyBackground:        @disneyBg;

// Links
// --------------------------------------------------

@linkColor:             #2697DD;
@linkColorHover:        darken(@linkColor, 15%);

// Navlist
// --------------------------------------------------
@navListColor :         @white;
@navListBGColor :       @linkColor;

// Tabs
// --------------------------------------------------
@tabBorderSize :          1px;
@tabBorderStyle :         solid;
@tabBorderbottomColor :   #000;
@tabBorderTopColor :      @bodyBackground;
@tabBorderLeftColor :     @bodyBackground;
@tabBorderRightColor :    @bodyBackground;
@tabTextColor :           @gray;
@tabBackgroundColor    :  @bodyBackground;

// PILLS
// --------------------------------------------------
@pillsColor :             @white;
@pillsBGColor :           #036aaa;






.nav-tabs,
.nav-pills {
  .clearfix();
}

.nav-tabs > li,
.nav-pills > li {
  float: left;
}

.nav-tabs > li > a,
.nav-pills > li > a {
  padding-right: 12px;
  padding-left: 12px;
  padding-top: 7px;
  padding-bottom: 7px;

  margin-right: 2px;
  line-height: 14px; // keeps the overall height an even number
  text-decoration:none;
}

.nav-tabs > li.disabled > a,
.nav-pills > li.disabled > a {
color:silver;
}

// Give the tabs something to sit on
.nav-tabs {
  border-bottom: 2px solid #ddd;
  margin-top: @baseLineHeight;
  margin-left:0px;
}

// Make the list-items overlay the bottom border
.nav-tabs > li {
  margin-bottom: -2px;
}
// Actual tabs (as links)
.nav-tabs > li > a {
  color: @gray;
  font-size: 16px;
  margin-right: 8px;
  //line-height: @baseLineHeight;
  border: none;
  border-bottom: 3px solid transparent;
  &.disabled {
    color: #ccc;
  }
  &:hover,
  &:focus {
    color: #2697dd;
    background-color: transparent;
    border-bottom-color: #036aaa;
    cursor: pointer;
    &.disabled {
      color: #ccc;
      border-bottom-color: transparent;
      cursor: default;
    }
  }
}
// Active state, and it's :hover/:focus to override normal :hover/:focus
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > .liactive > a:focus {
  color: #2697dd;
  background-color: transparent;
  border: none;
  border-bottom: 3px solid #036aaa;

}


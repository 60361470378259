//
// Quicklinks
// --------------------------------------------------


.quicklinks {
  padding: 0px 0px;
  margin-bottom: 0px;
  margin-top:10px;
  margin-left:0px;
  list-style: none;
  background-color: transparent;
  border-radius: 0;

  > li {
    display: inline;

    + li:before {
      content: ">\00a0"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: #000;
    }
  }

  > .active {
    color: silver;
  }
}
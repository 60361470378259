// Pills
//
// All HTML headings, &lt;h1&gt; through &lt;h6&gt;.
//
// markup:
// <ul class="nav nav-pills">
//   <li class="active"><a href="#">Home</a></li>
//   <li><a href="#">Profile</a></li>
//   <li><a href="#">Messages</a></li>
// </ul>
//
// Styleguide 11.1

.nav-tabs,
.nav-pills {
  .clearfix();
}

.nav-tabs > li,
.nav-pills > li {
  float: left;
}

.nav-tabs > li > a,
.nav-pills > li > a {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: 2px;
  line-height: 14px; // keeps the overall height an even number
}

.nav-tabs > li.disabled > a,
.nav-pills > li.disabled > a {
color:silver;
border-width: 0px;
}

.nav-tabs>.active>a, 
.nav-tabs>.active>a:focus, 
.nav-tabs>.active>a:hover {
  border: 0px solid transparent;
  cursor: default;
}
.nav-tabs>.active>a, .nav-tabs>.active>a:focus, .nav-tabs>.active>a:hover {
border-bottom: 3px solid #036aaa;
cursor: default;
}
// TABS
// --------------------------------------------------

// Give the tabs something to sit on
.nav-steps {
  border-bottom: 2px solid #ddd;
  margin-top: @baseLineHeight;
  padding-right: 20px;
  display: inline-block;  
}

// Make the list-items overlay the bottom border
.nav-steps > li {
  margin-bottom: -2px;
}
// Actual tabs (as links)
.nav-steps > li > a {
  color: @gray;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
  margin-right: 0px;
  //line-height: @baseLineHeight;
  border: none;
  border-bottom: 3px solid transparent;
  &.disabled {
    color: #ddd;
    border-width: 0px;
  }
  &:hover,
  &:focus {
    color: #ccc;
    background-color: transparent;
    border-bottom-color: #ccc;
    cursor: pointer;
    &.disabled {
      border-bottom-color: transparent;
      border-width: 0px;
      cursor: default;
    }
  }
}
// Active state, and it's :hover/:focus to override normal :hover/:focus
.nav-steps > .active > a,
.nav-steps > .active > a:hover,
.nav-steps > .active > a:focus {
  color: #2697dd;
  background-color: transparent;
  //border: 2px solid transparent;
  border-bottom-color: #036aaa;
  cursor: default;

}
// Active state, and it's :hover/:focus to override normal :hover/:focus
.nav-steps > .complete > a,
.nav-steps > .complete > a:hover,
.nav-steps > .complete > a:focus {
  color: #000;
  background-color: transparent;
  border-bottom-color: #036aaa;
}

.nav-steps > li > a .badge {
  background-color: #ccc;	
  color:#ffff;
  top:-1px;
  position:relative
}
.nav-steps > .active > a .badge,
.nav-steps > .active > a:hover .badge,
.nav-steps > .active > a:focus .badge{
	background-color: #2697dd;
}
.nav-steps > .complete > a .badge,
.nav-steps > .complete > a:hover .badge,
.nav-steps > .complete > a:focus .badge{
	background-color: green;
}
// Language Bar
//
// Single-line list items
//
// markup:
// <ol class="inline">
//  <li>Coffee</li>
//  <li>Tea</li>
//  <li>Milk</li>
// </ol>
//
// <ul class="inline">
//  <li>Coffee</li>
//  <li>Tea</li>
//  <li>Milk</li>
// </ul>
//
// Styleguide 1.15.3


.languagebar ul {
  margin-left: 0;
  list-style: none;
  > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    width:80px;
  }
}

.btn-selected {
	.btn-primary
}

body.en { 
.fr{
	display:none;
}
}

body.fr { 
	.en {
		display:none;
	}
}
/**
 * This file should be use to store any specific css required for the tables.
 **/

// hardcoded width to override datatables width:0px as it causes problems with IE
.datatablesSynopsis {
    min-width:300px;
}

.dataTables_wrapper .dataTables_processing{
  z-index: @zindexDatatableProcessing;
  background-color: #ffffff;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  margin: 0 auto;
  background: rgba(153, 153, 153, 0.54);
}

table.small td, table.small th {
  padding: 5px;
}
table.small th {
  width: 60px;
}
